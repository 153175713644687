import UploadIcon from '@mui/icons-material/Upload';
import Avatar from "@mui/material/Avatar";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import LinearProgress from '@mui/material/LinearProgress';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { userContext } from "../../stores/users/userContext";
import { getLocalStorage } from "../../utils";
import { presignedUrlEndpoint, updateUploadVideoData } from "../constants";
import getCookies from "../CSRF/getCookies";

const UpVideos = () => {
    const userStore = useContext(userContext)
    const [csrfToken, setCsrfToken] = useState(getCookies('csrftoken'))
    const {register, handleSubmit, formState: {errors}} = useForm()
    const [upload, setUpload] = useState(true)
    const [message, setMessage] = useState(null)
    const [upError, setUpError] = useState(null)
    const [title, setTitle] = useState('')
    const [file, setFile] = useState('')
    const token = getLocalStorage('token')
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);


    const onSubmit = async (data) => {
        // generate a uuid for the video in hex format     
        const uuid = uuidv4().toString()
        // Create a new filename with UUID
        const fileExt = file.name.split('.').pop();
        const uuidFilename = `${uuid}.${fileExt}`;
        
        handleUploadState()
        setIsUploading(true)
        setUploadProgress(0)

        try {
            // First get the presigned URL, including the title in the request
            const presignedUrlResponse = await axios.post(presignedUrlEndpoint, 
                new URLSearchParams({
                    'filename': uuidFilename, // Send UUID filename instead of original
                    'token': token,
                    'title': data.title,
                    'size': file.size,
                    'uuid': uuid,
                    'original_filename': file.name // Send original filename for reference
                }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });

            if (!presignedUrlResponse.data) {
                throw new Error('Failed to get upload URL');
            }

            const { url, object_key } = presignedUrlResponse.data;

            // Upload directly to S3 using axios with modified CORS settings
            const uploadResponse = await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
                withCredentials: false,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });

            if (!uploadResponse.status === 200) {
                throw new Error('Failed to upload file');
            }else{
                // update the video in the database
                await axios.post(updateUploadVideoData, {
                  'object_key': object_key,
                  'title': data.title,
                  'size': file.size,
                  'token': token,
                  'uuid': uuid,
                  'original_filename': file.name
                });
            }

            
            // Success! No need to make another call to the backend
            setMessage(`An email will be sent to ${userStore.getUserEmail()} when the video is ready for use.`);
            resetState();

        } catch (error) {
            const message = error.response?.data?.message || error.message || 'Upload failed';
            setUpError(message);
            handleUploadState();
        } finally {
            setIsUploading(false)
        }
    }

    const resetState = () => {
        setUpload(true)
        setMessage(null)
        setTitle('')
        setFile('')
        // Reset the file input by clearing its value
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
            fileInput.value = '';
        }
        // Reset the title input
        const titleInput = document.querySelector('input[name="title"]');
        if (titleInput) {
            titleInput.value = '';
        }
        handleUploadState()
    }

    const handleUploadState = () => {
        console.log(title, file)
        console.log(title === '' || file === '')
        setUpload(title === '' || file === '')
    }
    const onErrorDismiss = () => {
        resetState()

    }

    const onMessageDismiss = () => {
        resetState()
        window.location.reload()
    }

    const onChangeUpload = (event) => {
        setFile(event.target.files[0])
        handleUploadState()
    }

    const onChangeTitle = (event) => {
        setTitle(event.target.value)
        handleUploadState()
    }
    return (
        <Box sx={{marginTop: 10, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <Avatar sx={{m: 1}}>
                    <UploadIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set a title and upload a video
                </Typography>
            </Box>
            <Box sx={{mt: 3}}>
                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                    <input hidden {...register('csrfmiddlewaretoken')} value={csrfToken}/>
                    <input hidden {...register('token')} value={userStore.getToken()}/>

                    <TextField {...register('upload')}
                               fullWidth
                               id="upload"
                               label=""
                               defaultValue=""
                               name="upload"
                               type="file"
                               accept="video/*"
                               mode="onChange"
                               onChange={onChangeUpload}
                               sx={{mt: 3, mb: 2}}
                    />
                    <TextField {...register('title')}
                               fullWidth
                               id="title"
                               label="title"
                               name="title"
                               type="text"
                               mode="onChange"
                               defaultValue=""
                               onChange={onChangeTitle}
                               required
                    />
                    <Button type="submit"
                            disabled={upload || isUploading}
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                        {isUploading ? 'Uploading...' : 'Upload video'}
                    </Button>

                    {isUploading && (
                        <Box sx={{ width: '100%', mt: 2 }}>
                            <LinearProgress variant="determinate" value={uploadProgress} />
                            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                                {`${Math.round(uploadProgress)}%`}
                            </Typography>
                        </Box>
                    )}
                </form>
                {message &&
                    <Typography
                        fullWidth
                        color="success"
                        sx={{mt: 3, mb: 2, width: 'inherit'}}>
                        {message}
                    </Typography>}
                {message &&
                    <Button
                        fullWidth
                        color="success"
                        variant="contained"
                        sx={{mt: 3, mb: 2, width: '100%'}}
                        onClick={() => {
                            onMessageDismiss()
                        }}>
                        Click to dismiss
                    </Button>}
                {upError &&
                    <Button
                        fullWidth
                        color="error"
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        onClick={onErrorDismiss}>
                        {upError}! Click to dismiss
                    </Button>}

            </Box>
        </Box>
    )
}

export default UpVideos