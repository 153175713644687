import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

export default function Home() {
    const [videoSrc, setVideoSrc] = useState<string | null>(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleVideoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.type === "video/mp4") {
                setVideoSrc(URL.createObjectURL(file));
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        if (event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];
            if (file.type === "video/mp4") {
                setVideoSrc(URL.createObjectURL(file));
            }
        }
    };

    return (
        <Container maxWidth="md">
            <Box 
                sx={{ 
                    textAlign: 'center', 
                    mt: 8, 
                    p: 4, 
                    bgcolor: 'background.paper', 
                    borderRadius: 2, 
                    boxShadow: 3 
                }}
            >
                <Typography 
                    variant="h3" 
                    component="h1" 
                    gutterBottom 
                    sx={{ color: 'primary.main', fontWeight: 'bold' }}
                >
                    Welcome to Emo Transcript
                </Typography>
                <Typography 
                    variant="h6" 
                    component="p" 
                    color="textSecondary"
                    sx={{ mb: 4, fontSize: '1.1rem' }}
                >
                    Transform your audio and video content into interactive transcripts with ease.
                    Emo Transcript brings the power of AI and emotional intelligence to transcription,
                    making content more accessible, engaging, and insightful.
                </Typography>

                {/* Drag and Drop Video Upload Section */}
                <Box 
                    sx={{ 
                        mt: 4, 
                        p: 3, 
                        border: `2px dashed ${isDragging ? '#1976d2' : '#ccc'}`, 
                        borderRadius: '8px', 
                        textAlign: 'center', 
                        cursor: 'pointer', 
                        bgcolor: isDragging ? '#f0f0f0' : 'background.paper'
                    }}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <input 
                        type="file" 
                        accept="video/mp4" 
                        style={{ display: 'none' }} 
                        id="video-upload" 
                        onChange={handleVideoUpload} 
                    />
                    <label htmlFor="video-upload">
                        <Button 
                            variant="contained" 
                            component="span" 
                            color="primary"
                            sx={{ px: 4, py: 1.5, fontSize: '1rem', fontWeight: 'bold' }}
                        >
                            Drop a file here
                        </Button>
                    </label>
                </Box>

                {/* Display Uploaded Video */}
                {videoSrc && (
                    <Box sx={{ mt: 4 }}>
                        <video 
                            width="100%" 
                            height="400px" 
                            controls
                            style={{ borderRadius: '8px', boxShadow: '2px 2px 10px rgba(0,0,0,0.1)' }}
                        >
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                )}

               
                
            </Box>
        </Container>
    );
}