import React, {useContext} from "react";
import {userContext} from "../../stores/users/userContext";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router-dom";
import Home from '../pages/home'
import Logout from "../logout/logout";
import About from "../pages/about";
import {SignUpLogin} from "../signupLogin/signupLogin";
import Contact from "../contact/contact";
import Pricing from "../pages/pricing";
import {GetUser} from "../signupLogin/get_user";
import MyCreations from "../mycreations/mycreations";
import NotFound from "../pages/not_found_404";
import Downloaded from "../downloaded/downloaded";
import CreateVideo from "../grid/createVideo";
import DnVideos from "../upLoadDownload/dnVideos";
import UpVideos from "../upLoadDownload/upVideos";

const Router = () => {
    const userStore = useContext(userContext)
    const navigate = useNavigate();

    // don't try to make a video if there is no transcript, this can happen if the user refreshes the page
    if (userStore.getUserTranscriptId() === null && window.location.pathname === '/create-video/') {
        navigate('/downloaded/')
    }

    if (userStore.isUserAuth()) {
        return (
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/home/" element={<Home/>}/>
                <Route exact path="/logout/" element={<Logout/>}/>
                <Route exact path="/about/" element={<About/>}/>
                <Route exact path="/contact/" element={<Contact/>}/>
                <Route exact path="/pricing/" element={<Pricing/>}/>
                <Route exact path="/downloaded/" element={<Downloaded/>}/>
                <Route exact path="/create-video/" element={<CreateVideo/>}/>
                <Route exact path="/my-creations/" element={<MyCreations/>}/>
                <Route exact path="/download-video/" element={<DnVideos/>}/>
                <Route exact path="/upload-video/" element={<UpVideos/>}/>
                <Route exact path="/getuser/" element={<GetUser/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/signup/" element={<SignUpLogin/>}/>
                <Route exact path="/login/" element={<SignUpLogin/>}/>
                <Route exact path="/logout/" element={<Logout/>}/>
                <Route exact path="/about/" element={<About/>}/>
                <Route exact path="/contact/" element={<Contact/>}/>
                <Route exact path="/pricing/" element={<Pricing/>}/>
                <Route exact path="/getuser/" element={<GetUser/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        )
    }
}

export default observer(Router)