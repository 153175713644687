
export const navItemsLogged = ['Home', 'Pricing', 'Contact', 'Log in', 'Sign up', 'About'];
export const navLinkLogged = ['/', '/pricing/', '/contact/', '/login/', '/signup/', '/about/'];


export const navItemsNotLogged = ['Home', 'Pricing', 'Contact', 'Log out', 'About'];
export const navLinkNotLogged = ['/', '/pricing/', '/contact/', '/logout/', '/about/'];

export const menuItemsNotLogged = [
    ['Home', '/'],
    ['Pricing', 'pricing/'],
    ['Contact', 'contact/'],
    ['About', 'about/'],
    ['-', ''],
    ['Log in', 'login/', null],
    ['Sign up', 'signup/', null],

]


export const menuItemsLogged = [

    ['Videos', 'downloaded/'],
    ['Upload', 'upload-video/'],
    ['Download', 'download-video/'],
    ['-', ''],
    ['Transcript', 'create-video/'],
    ['-', ''],
    ['My creations', 'my-creations/'],
    ['-', ''],
    ['Delete', 'delete/'],
    ['Usage and progress', ''],
    ['-', ''],
    ['Profile', ''],
    ['Log out', 'logout/'],
    ['-', ''],
    ['Pricing', 'pricing/'],
    ['Contact', 'contact/'],
    ['About', 'about/'],
]

