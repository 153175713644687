// Django server

// export const domain = process.env.REACT_APP_DOMAIN;
// export const domainNoSlash = domain.replace(/\/$/, '');
// export const hostApi = process.env.REACT_APP_HOST;
//



// Development localstack, the endpoint is http://localhost:4566/
// Production localstack, the endpoint is http://localhost.localstack.cloud:4566/
// export const hostS3 = process.env.REACT_APP_S3_ENDPOINT_URL;
let domain;
let hostApi;
let hostS3;

const hostname = window.location.hostname.split('.')[0];
console.log('window.location.hostname', hostname);


switch (hostname) {
    case 'localhost':
        domain = 'http://localhost:8000/';
        hostApi = 'http://localhost:8000/api/v1/';
        hostS3 = 'http://localhost:4566/';
        break;
    case 'develop':
        domain = 'https://develop.emotranscripts.com';
        hostApi = 'https://develop.emotranscripts.com/api/v1/';
        hostS3 = 'https://s3.us-east-2.amazonaws.com/';
        break;
    case 'www':
        domain = 'https://www.emotranscripts.com';
        hostApi = 'https://www.emotranscripts.com/api/v1/';
        hostS3 = 'https://s3.us-east-2.amazonaws.com';
        break;
}

console.log('domain', domain);
console.log('hostApi', hostApi);
console.log('hostS3', hostS3);

export { domain, hostApi, hostS3 };

// Development localstack, the endpoint is http://localhost:4566/
// Production localstack, the endpoint is http://localhost.localstack.cloud:4566/
console.log('domain', domain);
console.log('hostApi', hostApi);
console.log('hostS3', hostS3);

export const authUrl = {
    'get_email': `${hostApi}get-email/`,
    'get_pass': `${hostApi}get-code/`,
    'signUp': `${hostApi}sign-up/`,
}
export const getUserUrl = `${hostApi}get-user/`;

export const logoutUrl = `${hostApi}logout/`;
export const csrfUrl = `${hostApi}csrf/`;
export const getUserVideosUrl = `${hostApi}user-downloaded-videos/`;
export const downloadUrl = `${hostApi}download-videos/`;
export const uploadUrl = hostS3
export const getSentencesUrl = `${hostApi}user-create/`;
export const makeVideoUrl = `${hostApi}make-video/`;
export const getMyCreationsUrl = `${hostApi}my-creations/`;
export const deleteUrl = `${hostApi}delete-downloaded-video/`;
export const host = 'http://localhost/';
export const getPagesUrl = `${hostApi}get-pages/`;
export const presignedUrlEndpoint = `${hostApi}generate-presigned-url/`;
export const updateUploadVideoData = `${hostApi}update-upload-video-data/`;