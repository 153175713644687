import React from "react";


const HasVideo = ({ data }) => {

    if (data['task_status'] === 'Pending' || data['task_status'] === 'In progress') {
        return (<div>No Video</div>)
    } else {
        return <video width="320" height="240" controls src={`${data['video']}`}/>
    }
}

export default HasVideo