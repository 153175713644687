import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { messageContext } from "../../stores/messages/messageContext";
import { userContext } from "../../stores/users/userContext";
import { getMyCreationsUrl } from '../constants';
import DownloadButton from "../download/downloadButton";
import Header from "../header/header";
import { myCreationHeader } from "../header/headerConstants";
import Loading from "../loading/loading";
import TextSubTitle from "../subtitles/subtitles";
import Title from "../title/title";
import './mycreations.css';


const MyCreations = () => {
    const userStore = useContext(userContext)
    const messageStore = useContext(messageContext)
    const [myCreations, setMyCreations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [header, setHeader] = useState(myCreationHeader)
    const [subtitleTexts, setSubtitleTexts] = useState({});

    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${getMyCreationsUrl}?token=${userStore.getToken()}`)
            const data = await response.json()
            setMyCreations(data)
            if (data.length === 0) {
                messageStore.setMessage("You haven't downloaded or uploaded any videos yet, but feel free to do so using options menu.", 'info', 10000)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            messageStore.setMessage(error)
        }
    }

    const fetchSubtitleText = async (url, id) => {
        try {
            const response = await fetch(url);
            const text = await response.text();
            setSubtitleTexts(prev => ({
                ...prev,
                [id]: text
            }));
        } catch (error) {
            console.error("Error fetching subtitle:", error);
            setSubtitleTexts(prev => ({
                ...prev,
                [id]: "Error loading subtitle"
            }));
        }
    };

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        myCreations.forEach(creation => {
            if (creation.ext === 'srt' || creation.ext === 'vtt') {
                fetchSubtitleText(creation.video, creation.id);
            }
        });
    }, [myCreations]);

    if (loading) return <Loading/>


    const itemContent = (item, data) => {
        return (
            header.map((obj, index) => {
                if (obj.visible) {
                    if (obj.type === 'video') {
                        if (data['ext'] === 'mp4')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <video playsInline width="320" height="240" src={data['video']} controls/>
                                </td>
                            )
                        if (data['ext'] === 'mp3')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <audio controls>
                                        <source src={data['video']} type="audio/mpeg"/>
                                    </audio>
                                </td>
                            )
                        if (data['ext'] === 'gif')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <DownloadButton url={data['video']} name={data['file_name']}/>
                                    <img width="320" src={data['video']} alt="gif creation"/>
                                </td>
                            )
                         if (data['ext'] === 'srt' || data['ext'] === 'vtt')
                            return (
                                <td key={index} className={`${obj.field} ${obj.type}`}>
                                    <TextSubTitle text={subtitleTexts[data.id] || "Loading subtitle..."} />
                                </td>
                            )
                        
                    }
                    if (obj.type === 'description')
                        return (
                            <td key={index} className={`${obj.field} ${obj.type}`}>
                                <Title title={data[obj.field]} maxWidth={obj.width}/>
                            </td>
                        )
                    if (obj.type === 'date')
                        return (
                            <td key={index} className={`${obj.field} ${obj.type}`}>
                                {data[obj.field]}
                            </td>
                        )
                    if (obj.type === 'string')
                        return (
                            <td key={index} className={`${obj.field} ${obj.type}`}>
                                <Title title={data[obj.field]} maxWidth={obj.width}/>
                            </td>
                        )
                }
                return null
            })
        )
    }


    return (
        <Box style={{width: '100%', margin: 5, paddingTop: 65}}>
            <TableVirtuoso
                style={{height: '100vh', width: '98hv', margin: '0 auto'}}
                fixedHeaderContent={() => <Header header={header} onClick={() => {
                }}/>}
                data={myCreations}
                itemContent={itemContent}
            />
        </Box>
    )
}

export default observer(MyCreations);