import React from 'react';
import {useNavigate} from "react-router-dom";
import Button from '@mui/material/Button';
import {userContext} from "../../stores/users/userContext";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {menuItemsNotLogged, menuItemsLogged} from "./constants";
import {useContext} from "react";
import {deleteUrl, makeVideoUrl} from "../constants";
import {messageContext} from "../../stores/messages/messageContext";
import getCookies from "../CSRF/getCookies";

const ITEM_HEIGHT = 200;
const ITEM = 0
const LINK = 1

const EmoMenu = () => {
    const messageStore = useContext(messageContext)
    const navigate = useNavigate();
    const userStore = useContext(userContext)
    const transcriptId = (userStore.getUserTranscriptId() === undefined ? null : userStore.getUserTranscriptId())
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const menuItems = (userStore.isUserAuth() ? menuItemsLogged : menuItemsNotLogged)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = (event, index) => {
        setAnchorEl(null);
    };
    const menuItemClick = (index) => {
        setAnchorEl(null);
        if (menuItems[index][LINK] === 'delete/') {
            deleteVideo()
        } else {
            navigate(menuItems[index][LINK])
        }
    }
    const enableButton = (item) => {
        console.log(item)
        console.log(transcriptId)
        return transcriptId === null && (item === 'Transcript' || item === 'Delete')
    }
    const deleteVideo = async (index) => {
        const data = {
            'token': userStore.getToken(),
            'transcript_id': transcriptId,
        }
        const urlEncodedData = new URLSearchParams();
        for (const pair of Object.entries(data)) {
            urlEncodedData.append(pair[0], pair[1])
        }
        const response = await fetch(deleteUrl, {
            method: 'POST',
            mode: 'cors',
            body: urlEncodedData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-CSRFToken': getCookies('csrftoken'),
            }
        })
        const result = await response.json()
        if (response.status === 200) {
            messageStore.setMessage(`video "${result.title}" was deleted`)
        } else {
            messageStore.setMessage('Video was not deleted.',)
        }
        // wait message to be displayed
        setTimeout(() => { window.location.reload(); }, 4100);

    }


    return (
        <div>
            <Button
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                color="inherit"
                onClick={handleClick}
            >
                Options
            </Button>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '100ch',
                    },
                }}
            >
                {menuItems.map((item, index) => (
                        (item[ITEM] === '-') ?
                            <Divider key={index}/> :
                            <MenuItem key={index}
                                      disabled={enableButton(item[ITEM])}
                                      onClick={() => menuItemClick(index)}>
                                {item[ITEM]}
                            </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
}

export default EmoMenu;